const config = {
  BOT_BASE_URL : 'https://gptchatbotdemo.shipsy.in',
  PROJECTX_BASE_URL: 'https://app.shipsy.in',
  SCRIPT_AUTOMATION_BASE_URL: 'https://internaldashboardapi.shipsy.in',
  GOOGLE_CLIENT_ID: '140812084518-ur26pmnhq3ps268aqnk94e5m9vjub8db.apps.googleusercontent.com',
  CT_INTERNAL_BASE_URL: 'https://shipperdashboardapp.shipsy.in',
  ENVIRONMENT: 'production',
  EXIM_DASHBOARD_URL : '.shipsy.app',
  SUPPORT_TOOL_BACKEND_URL : 'https://supporttoolapi.shipsy.in',
  SUPPORT_TOOL_AllOWED_FILE_TO_UPLOAD: '.jpeg,.pdf,.png,.xls,.xlsx,.docx',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBsf9ckQz2miSo4VwmRa1_jAdoqSxxyFwU',
  CUSTOM_DEPLOYMENT_BACKEND_URL: "https://customdeploymentmanagedev.shipsy.in",
  PROJECTX_INTEGRATION_CONFIG: {
        '1': 'https://dtdcapi.shipsy.io',
        'ajex': 'https://ksaapi.shipsy.io',
	'ksaorgdemo': 'https://ksaapi.shipsy.io',
	'tibbygo': 'https://ksaapi.shipsy.io',
	'ypserverdemo': 'https://yemenapi.shipsy.io',
	'yemenpost': 'https://yemenapi.shipsy.io',
	'uaedemo': 'https://projectxuaeapi.shipsy.io',
        'zajel': 'https://projectxuaeapi.shipsy.io',
	'hellmann': 'https://projectxeuapi.shipsy.io',
        'burjeelpharmacy': 'https://projectxuaeapi.shipsy.io',
	'sterling': 'https://projectxuaeapi.shipsy.io',
	'floward': 'https://projectxuaeapi.shipsy.io',
	'shipsyazure': 'https://swedenapi.shipsy.io',
        'eudemo': 'https://projectxeuapi.shipsy.io',
	'chronodiali': 'https://projectxuaeapi.shipsy.io',
        'gmggroupsupercare': 'https://projectxuaeapi.shipsy.io',
        'gmggrouphealthcare': 'https://projectxuaeapi.shipsy.io',
	'fkdemo': 'https://app-gam.shipsy.io',
        'flipkarthl': 'https://app-gam.shipsy.io',
	'fk': 'https://app-gam.shipsy.io',
        'flipkartnfr': 'https://app-gam.shipsy.io',
	'amresttest': 'https://projectxeuapi.shipsy.io',
        'hellmann-nz': 'https://projectxeuapi.shipsy.io',
        'hellmann-au': 'https://projectxeuapi.shipsy.io',
        'fkdhoffload': 'https://app-gam.shipsy.io',
        'qpstaging': 'https://app-gmq.shipsy.io',
        'hellmannnz': 'https://projectxeuapi.shipsy.io'
    },
  ETL_DASHBOARD_BACKEND_URL: 'https://etl.shipsy.in/',
  JOB_SCHEDULER_BASE_URL: 'https://cronprodapi.shipsy.io',
  HYBRID_CACHE_MANAGEMENT: {
    BASE_URL: 'https://jib9rc19ma.execute-api.us-west-2.amazonaws.com/prod/manage-hybrid-cache',
    API_KEY: 'SA6r7K3PqI0Kagr2FKv7U4l0m5QtJWPk2HMlMwCL'
  },
  DEAD_LETTER_BASE_URL: 'https://deadletter.shipsy.io',
  DATABASE_MAINTENANCE_URL: 'https://database-maintenance.shipsy.in',
  WAR_COMMAND_CENTRE_BASE_URL: 'https://warcommandbackend.shipsy.in',
  SUPPORT_LOGIN_REDIRECT_MAP: {
    '1': 'https://ops-dashboard.dtdc.in',
    fkdemo: 'https://fkdemo.portal.gam.shipsy.io',
    fk: 'https://fk.portal.gam.shipsy.io',
    flipkarthl: 'https://flipkarthl.portal.gam.shipsy.io',
    flipkartnfr: 'https://flipkartnfr.portal.gam.shipsy.io',
    fkdhoffload: 'https://fkdhoffload.portal.gam.shipsy.io',
    'hellmann-au': 'https://hellmann-au.portal.eu.shipsy.io',
    'hellmann-nz': 'https://hellmann-nz.portal.eu.shipsy.io',
    'qpstaging': 'https://qpstaging.portal.gmq.shipsy.io',
    "hellmannnz": 'https://hellmannnz.portal.eu.shipsy.io'
  },
  DATABASE_MIGRATION_TOOL_URL : 'https://dbmigration.shipsy.io',
  LOW_CODE_BASE_URL: 'https://lowcodeapi.shipsy.io',
  LTL_BASE_URL: 'https://ltlapi.shipsy.in'
};

export default config;

